<template>
  <label class="checkbox" :class="[{ 'disabled': disabled }, { 'checked': checked || isIndeterminate }]" :style="masterStyle">
    <img src="../../assets/icons/check.svg" v-if="checked"  />
    <img src="../../assets/icons/minus.svg" v-else-if="isIndeterminate" />
    <input class="controller" type="checkbox" v-model="val" :value="value" @change="toggle" :disabled="disabled" />
  </label>

</template>

<script>
const DEFAULT_CHECKBOX_SIZE = 16;
const DEFAULT_BORDER_SIZE = 1;

export default {
  name: "Checkbox",
  model: {
    prop: "checked",
    event: "input"
  },
  props: {
    checked: [Array, Boolean],
    value: [Object, String, Number, Boolean, Array],
    readonly: {
      type: Boolean,
      default: false
    },
    size: {
      type: [Number, String],
      default: 16
    },
    disabled: {
      type: Boolean,
      default: false
    },
    isIndeterminate: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      val: false
    };
  },
  computed: {
    toggleClass() {
      return this.val ? "on" : "off";
    },

    masterStyle() {
      const borderWidth = (this.sizeNum - DEFAULT_CHECKBOX_SIZE) < 0
        ? 1
        : (this.sizeNum - DEFAULT_CHECKBOX_SIZE) * 0.25 / 4 + 1;

      return {
        width: (this.sizeNum) + this.sizeUnit,
        height: (this.sizeNum) + this.sizeUnit,
        borderWidth: borderWidth + this.sizeUnit
      };
    },

    sizeNum() {
      if (typeof this.size == "number") {
        return this.size;
      }
      const size = this.size;
      const parts = size.split(/([a-zA-Z%]+)/);
      return Number(parts[0]) || 16;
    },

    sizeUnit() {
      if (typeof this.size == "number") {
        return "px";
      }
      const size = this.size;
      const parts = size.split(/([a-zA-Z%]+)/);
      return parts[1] || "px";
    },

    iconStyle() {
      return {
        fontSize: (this.sizeNum * 5 / 8) + this.sizeUnit,
      };
    }
  },

  watch: {
    checked(newV) {
      if (this.val != newV) this.val = newV;
    }
  },

  created() {
    this.val = this.checked;
  },

  methods: {
    toggle() {
      if (this.disabled || this.readonly) {
        return;
      }
      this.$emit("input", this.val);
    },
    click() {
      if (this.disabled || this.readonly) {
        return;
      }
      const newChecked = [...this.checked];
      if (this.checked.includes(this.value)) {
        newChecked.splice(newChecked.indexOf(this.value), 1);
      } else {
        newChecked.push(this.value);
      }
      this.$emit("input", newChecked);
    }
  }
};
</script>

<style lang="scss" scoped>
label {
  padding: 0;
}
.checkbox {
  border-style: solid;
  border-color: #9e9e9e;
  border-radius: 3px;
  display: flex;
  align-items: center;
	justify-content: center;
  transition: all 0.25s ease;
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  &:not(.disabled):hover {
    border-color: #666666;
    background-color: #e2e2e2;
    cursor: pointer;
  }

  &.checked {
    background: #3c67ff;
    border-color: #3c67ff;

    &:hover {
      background: #3c67ff;
      border-color: #3c67ff;
    }
  }

  img {
    width: 12px;
    height: 12px;
  }
}
</style>
