<template>
    <div class="gp-checkbox-tree" :class="clases">
        <p class="gp-checkbox-tree__ttl-wrapper">
          <span class="gp-checkbox-tree__ttl">{{ title }}</span>
          <span
            class="gp-checkbox-tree__clear-btn"
            :class="{ 'gp-checkbox-tree__clear-btn--active': checkedItem.length > 0 }"
            @click="onClearFilter"
          >
            Clear
          </span>
        </p>
        <CheckBoxGroup
            v-for="(item, index) in data"
            :key="item.handle"
            :value="checkedItem"
            :item="item"
            :index="index"
            :onToggleCheckbox="onToggleCheckbox"
            :class="`
              ${(item.children && item.children.length)
                ? 'gp-checkbox-tree__item--mb-24'
                : 'gp-checkbox-tree__item--mb-16'
              }
            `"
        />

    </div>
</template>

<script>
import CheckBoxGroup from "./CheckBoxGroup.vue";
import Helper from "./Helper";

export default {
  name: "CheckBoxTree",

  components: {
    CheckBoxGroup,
  },

  props: {
    clases: {
      type: String,
      default:  "",
    },
    title: {
      type: String,
      required:  true,
    },
    data: {
      type: Array,
      required: true,
    }
  },

  data() {
    return {
      checkedItem: [],
    };
  },

  methods: {
    onClearFilter() {
      this.checkedItem = [];
      this.$emit("change", []);
    },

    onToggleCheckbox(isChecked, arrayPath) {
      const targetItem = Helper.getItemByPath(this.data, arrayPath);
      if (!targetItem) {
        return;
      }

      if (isChecked) {
        // Handle check item
        this.handleCheckItem(targetItem, arrayPath);
      } else {
        // Handle uncheck item
        this.handleUncheckItem(targetItem, arrayPath);
      }

      this.$emit("change", [...this.checkedItem]);
    },

    handleCheckItem(item, arrayPath) {
      // Add item to checked list
      this.checkedItem.push(item.handle);

      // Add all children to checked list
      if (item.children) {
        this.checkedItem = Helper.makeArrayUniq([
          ...this.checkedItem,
          ...Helper.extractChildHandles(item),
        ]);
      }

      // Check if should add parent item to checked list
      if (arrayPath.length > 1) {
        arrayPath.pop();
        while (arrayPath.length > 0) {
          const parentItem = Helper.getItemByPath(
            this.data,
            arrayPath
          );
          arrayPath.pop();
          if (!parentItem) {
            break;
          }

          const childHandles = Helper.extractChildHandles(parentItem);
          const mergeData = Helper.makeArrayUniq([
            ...this.checkedItem,
            ...childHandles,
          ]);

          // If length of unique merge array of checked list and childHandles handle are equal
          // => No new item added to checked list
          // => All item in childHandles already in checked list
          // => Add parent handle to checked list
          if (mergeData.length === this.checkedItem.length) {
            this.checkedItem.push(parentItem.handle);
          }
        }
      }
    },

    handleUncheckItem(item, arrayPath) {
      const itemIndex = this.checkedItem.indexOf(item.handle);
      if (itemIndex === -1) {
        return;
      }

      // Remove item from checked list
      this.checkedItem.splice(itemIndex, 1);

      // Remove all child"s id from checked list
      if (item.children) {
        const childHandles = Helper.extractChildHandles(item);
        this.checkedItem = this.checkedItem.filter(
          (checkedId) => !childHandles.includes(checkedId)
        );
      }

      // Remove parent from checked list because parent only checked
      // when every child in checked list
      if (arrayPath.length > 1) {
        arrayPath.pop();
        while (arrayPath.length > 0) {
          const parentItem = Helper.getItemByPath(
            this.data,
            arrayPath
          );
          arrayPath.pop();
          if (!parentItem) {
            break;
          }

          const parentItemIndex = this.checkedItem.indexOf(parentItem.handle);
          if (parentItemIndex !== -1) {
            this.checkedItem.splice(parentItemIndex, 1);
          }
        }
      }
    },

    handleUpdateCheckList(data) {
      this.checkedItem = data;
    }
  },
};
</script>

<style lang="scss" scoped>
  .gp-checkbox-tree {
    padding: 0 16px 0 24px;
    &__ttl-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 18px;
    }
    &__ttl {
      text-transform: uppercase;
      font-weight: 600;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.4px;
    }
    &__item {
      &--mb-24 {
        margin-bottom: 24px;
      }
      &--mb-16 {
        margin-bottom: 16px;
      }
    }
    &__clear-btn {
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      color: #e2e2e2;
      cursor: not-allowed;
      &--active {
        cursor: pointer;
        color: #f44336;
      }
    }
  }
</style>
