export default class Helper {
    static getItemByPath(rootObject, arrayPath) {
        let item = rootObject;
        let index = 0;
        while (index < arrayPath.length) {
            if (index === 0) {
                item = item[arrayPath[index++]];
                continue;
            }

            if (index !== 0 && item.children) {
                item = item.children[arrayPath[index++]];
                continue;
            }

            break;
        }

        return item;
    }

    static extractChildHandles(dataObject) {
        let handles = [];
        if (!dataObject.children) {
            return handles;
        }

        for (let child of dataObject.children) {
            handles.push(child.handle);
            if (child.children) {
                handles = [...handles, ...Helper.extractChildHandles(child)];
            }
        }

        return handles;
    }

    static getChildCount(dataObject) {
        let count = 0;
        if (!dataObject.children) {
            return count;
        }

        for (let child of dataObject.children) {
            count += child.count;
            if (child.children) {
                count += Helper.getChildCount(child);
            }
        }

        return count;
    }

    static makeArrayUniq(dataArray) {
        return [...new Set([...dataArray])];
    }
}
