<template>
<div class="gp-checkbox-item">
  <div class="gp-checkbox-item__wrapper" @click="onToggle">
    <Checkbox
      :value="item.handle"
      :checked="checked"
      :size="16"
      :isIndeterminate="isIndeterminate"
      @input="onToggle"
    />
    <span
      class="gp-checkbox-item__label"
      :class="{ 'gp-checkbox-item__label--bold': depth === 0 }"
    >
      {{ item.name }}
    </span>
  </div>
</div>
</template>

<script>
import Checkbox from "./Checkbox.vue";

export default {
  components: {
    Checkbox
  },

  props: {
    item: {
      type: Object,
      required: true,
    },
    checked: {
      type: Boolean,
      required: true,
    },
    isIndeterminate: {
      type: Boolean,
      required: true,
    },
    depth: {
      type: Number,
      required: true,
    },
    onChange: {
      type: Function,
      required: true,
    },
  },

  methods: {
    onToggle() {
      this.onChange(!this.checked);
    },
  }
};
</script>

<style lang="scss">
.gp-checkbox-item {
  $root: &;
  &__wrapper {
    display: flex;
    align-items: center;
    cursor: pointer;
    &:hover {
      #{$root}__label {
        color: #212121;
      }
      .checkbox {
        &:not(.disabled) {
          border-color: #666666;
          background-color: #e2e2e2;
          cursor: pointer;
        }
        &.checked {
          background: #3c67ff;
          border-color: #3c67ff;
        }
      }
    }
  }
  &__label {
    color: #676767
    ;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    margin-left: 10px;
    &--bold {
      font-weight: 500;
      color: #212121;
    }
  }
  &__count {
    font-size: 10px;
    line-height: 12px;
    font-weight: 500;
    border-radius: 6px;
    background-color: #eeeeee;
    text-align: center;
    color: #212121;
    padding: 0 4px;
  }
}
</style>
