<template>
    <div class="gp-checkbox-group">
        <CheckBoxItem
            :item="item"
            :checked="value.includes(item.handle)"
            :isIndeterminate="!!(item.children) && hasChildrenCheck"
            :depth="depth"
            :onChange="onChange"
        />
        <div v-if="item.children && item.children.length > 0" class="gp-checkbox-group__child-wrapper">
            <CheckBoxGroup
                v-for="(child, childIndex) in item.children"
                :key="child.handle"
                :value="value"
                :item="child"
                :index="childIndex"
                :parentId="item.handle"
                :parentIndex="[...parentIndex, index]"
                :onToggleCheckbox="onToggleCheckbox"
                :depth="depth + 1"
            />
        </div>
    </div>
</template>

<script>
import CheckBoxItem from "./CheckBoxItem.vue";
import Helper from "./Helper";

export default {
  name: "CheckBoxGroup",

  components: {
    CheckBoxItem,
  },

  props: {
    value: {
      type: Array,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    parentId: {
      type: String,
      default: undefined,
    },
    parentIndex: {
      type: Array,
      default: () => [],
    },
    onToggleCheckbox: {
      type: Function,
      required: true,
    },
    depth: {
      type: Number,
      default: 0,
    },
  },

  computed: {
    childHandles() {
      return Helper.extractChildHandles(this.item);
    },

    hasChildrenCheck() {
      if (!this.item.children) {
        return false;
      }

      const mergeArr = [...this.value, ...this.childHandles];

      return [...new Set(mergeArr)].length !== mergeArr.length;
    },
  },

  methods: {
    onChange(value) {
      this.onToggleCheckbox(value, [...this.parentIndex, this.index]);
    },
  },
};
</script>

<style lang="scss" scoped>
.gp-checkbox-group {
    margin-bottom: 10px;
    &__child-wrapper {
        margin-top: 10px;
    }
}
</style>
